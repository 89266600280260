import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const useUserStore = create(
  persist(
    (set) => ({
      userData: {
        fullname: null,
        companyName: null,
        email: null,
        chatbot: null,
      },
      updateUserState: (newData) =>
        set((state) => ({
          userData: { ...state.userData, ...newData },
        })),
    }),
    { name: "user-store", storage: createJSONStorage(() => sessionStorage) },
  ),
);

export default useUserStore;
