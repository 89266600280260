import React from "react";
import MainMenu from "./MainMenu";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import MenuProvider from "./hooks/menu-visibility";
import { Auth0ProviderWithNavigate } from "./Auth0ProviderWithNavigate";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <Auth0ProviderWithNavigate>
      <MenuProvider>
        <div className="flex flex-col h-screen justify-between">
          <MainMenu></MainMenu>
          <Outlet />
          <Footer></Footer>
          <ToastContainer />
        </div>
      </MenuProvider>
    </Auth0ProviderWithNavigate>
  );
}

export default App;
