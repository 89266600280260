import React from "react";
import { Button, Navbar } from "flowbite-react";
import myLogo from "/logo.png";
import MyNavlink from "./MyNavlink";
import { COMPANY_NAME } from "./constants";
import { useAuth0 } from "@auth0/auth0-react";
import { useMenuVisibility } from "./hooks/menu-visibility";
import useUserStore from "./hooks/user-store";

function MainMenu() {
  const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();
  const updateUserState = useUserStore((state) => state.updateUserState);

  if (isAuthenticated && user) {
    updateUserState({
      fullname: user.name,
      email: user.email,
    });
  }

  console.log("user", user);
  const { isMenuVisible } = useMenuVisibility();
  if (!isMenuVisible) {
    return null;
  }
  return (
    <Navbar fluid rounded>
      <Navbar.Brand href="/">
        <img
          src={myLogo}
          className="mr-3 h-6 sm:h-9"
          alt={`${COMPANY_NAME} Logo`}
        />
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
          {COMPANY_NAME}
        </span>
      </Navbar.Brand>
      <div className="flex md:order-2">
        <Button
          as="a"
          target="_blank"
          href="https://chat.sitechatpro.nl/"
          className="bg-primary-700"
        >
          Nu Uitproberen!
        </Button>
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        <MyNavlink to="/">Home</MyNavlink>
        {/* <MyNavlink to="/over">Over</MyNavlink> */}
        <MyNavlink to="/features">Features</MyNavlink>
        <MyNavlink to="/prijzen">Prijzen</MyNavlink>
        {/* <MyNavlink to="/contact">Contact</MyNavlink> */}

        {isAuthenticated && <MyNavlink to="/profile">Profiel</MyNavlink>}
        {isAuthenticated && <MyNavlink to="/dashboard">Dashboard</MyNavlink>}
        {!isAuthenticated && <MyNavlink to="/login">Inloggen</MyNavlink>}
        {isAuthenticated && (
          <Navbar.Link
            onClick={() =>
              logout({ logoutParams: { returnTo: window.location.origin } })
            }
          >
            Uitloggen
          </Navbar.Link>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
}

export default MainMenu;
