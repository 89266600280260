import React from "react";

const ChatStatus = ({ status }) => {
  // switch case status to set statusColor, opened = green, closed = red, pending = yellow
  // read: https://tailwindcss.com/docs/content-configuration#dynamic-class-names
  let statusColor = "bg-gray-400";
  switch (status) {
    case "opened":
      statusColor = "bg-green-400";
      break;
    case "closed":
      statusColor = "bg-yellow-200";
      break;
    case "blocked":
      statusColor = "bg-red-400";
      break;
    default:
      statusColor = "bg-gray-400";
  }

  return (
    <div className="flex items-center">
      <div
        className={`inline-block w-4 h-4 mr-2 ${statusColor} rounded-full`}
      ></div>
      {status}
    </div>
  );
};

export default ChatStatus;
