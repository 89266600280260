import React, { useEffect, useState } from "react";
import { printDateInUserLocale } from "./utils/date";
import { useApiService } from "./hooks/apiService";
import { Pagination } from "flowbite-react";
import { Link } from "react-router-dom";
import useUserStore from "./hooks/user-store";
import ChatStatus from "./components/ChatStatus";
import EyeIcon from "./icons/EyeIcon";

export default function Leads() {
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const { apiService } = useApiService();
  const chatbotUUID = useUserStore((state) => state?.userData?.chatbot?.uuid);

  async function getTableData() {
    if (chatbotUUID !== "") {
      let data = await apiService.getLeads(chatbotUUID, currentPage);
      setTableData(data);
      setTotalItems(data.total_items);
    }
  }

  useEffect(() => {
    getTableData();
  }, [chatbotUUID, currentPage]);

  return (
    <div className="mb-4 bg-white rounded shadow p-6">
      <h2 className="mb-3 text-xl font-bold text-gray-900 dark:text-white">
        Leads
      </h2>
      <section className="bg-white antialiased dark:bg-gray-900">
        <div className="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="p-4">
                    <div className="flex items-center">
                      <input
                        id="checkbox-all"
                        type="checkbox"
                        className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      ></input>
                      <label htmlFor="checkbox-all" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </th>
                  <th scope="col" className="px-4 py-3">
                    User
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Site Count
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Generated Sites
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Gemaakt op
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Laatste update
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row) => (
                  <tr
                    key={row.lead_id}
                    className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
                  >
                    <td className="w-4 px-4 py-3">
                      <div className="flex items-center">
                        <input
                          id="checkbox-table-search-1"
                          type="checkbox"
                          onClick={(event) => event.stopPropagation()}
                          className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        ></input>
                        <label
                          htmlFor="checkbox-table-search-1"
                          className="sr-only"
                        >
                          checkbox
                        </label>
                      </div>
                    </td>
                    <th
                      scope="row"
                      className="flex items-center px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      <Link
                        // to={`/dashboard/leads/${row.lead_id}`}
                        className="hover:underline"
                      >
                        <span className="bg-primary-100 text-primary-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-primary-900 dark:text-primary-300">
                          {row?.chat_user.username}
                        </span>
                        <EyeIcon />
                      </Link>
                      {/* put a eye view icon here */}
                    </th>
                    <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {row?.site_configs?.length || 0}
                    </td>
                    <td className="px-4 py-2 font-medium text-gray-900 whitespace-normal dark:text-white flex flex-col">
                      {row?.site_configs?.map((site) => {
                        return (
                          <a
                            href={site.site_url}
                            rel="noreferrer"
                            target="_blank"
                            className="text-blue-600 hover:underline"
                          >
                            {site.site_url.replace("https://", "")}
                          </a>
                        );
                      })}
                    </td>
                    <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {printDateInUserLocale(row.created_at)}
                    </td>
                    <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {printDateInUserLocale(row.updated_at)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* <Pagination */}
          {/*   currentPage={currentPage} */}
          {/*   pageSize={25} */}
          {/*   totalItems={totalItems} */}
          {/*   onPageChange={(page) => { */}
          {/*     setCurrentPage(page); */}
          {/*   }} */}
          {/* /> */}
        </div>
      </section>
    </div>
  );
}
