import React from "react";

const Pagination = ({ currentPage, pageSize, totalItems, onPageChange }) => {
  console.log(currentPage, pageSize, totalItems);
  const totalPages = Math.ceil(totalItems / pageSize);
  console.log(totalPages);
  const pages = [...Array(totalPages).keys()].map((page) => page + 1);

  const firstPageItem = currentPage * pageSize - pageSize + 1;
  const lastPageItem = Math.min(currentPage * pageSize, totalItems);
  return (
    <nav
      class="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0"
      aria-label="Table navigation"
    >
      <span class="text-sm font-normal text-gray-500 dark:text-gray-400">
        Showing{" "}
        <span class="font-semibold text-gray-900 dark:text-white">
          {firstPageItem}-{lastPageItem}
        </span>{" "}
        of{" "}
        <span class="font-semibold text-gray-900 dark:text-white">
          {totalItems}
        </span>
      </span>
      <ul class="inline-flex items-stretch -space-x-px">
        <li>
          <a
            href="#"
            class="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            onClick={() => {
              if (currentPage > 1) {
                onPageChange(currentPage - 1);
              }
            }}
          >
            <span class="sr-only">Previous</span>
            <svg
              class="w-5 h-5"
              aria-hidden="true"
              fill="currentColor"
              viewbox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </a>
        </li>
        {pages.map((page) => (
          <li key={page}>
            <a
              href="#"
              onClick={() => onPageChange(page)}
              aria-current={page === currentPage ? "page" : null}
              class={
                page !== currentPage
                  ? "flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  : "z-10 flex items-center justify-center px-3 py-2 text-sm leading-tight border text-primary-600 bg-primary-50 border-primary-300 hover:bg-primary-100 hover:text-primary-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
              }
            >
              {page}
            </a>
          </li>
        ))}
        {/* <li> */}
        {/*   <a */}
        {/*     href="#" */}
        {/*     class="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" */}
        {/*   > */}
        {/*     ... */}
        {/*   </a> */}
        {/* </li> */}
        {/* <li> */}
        {/*   <a */}
        {/*     href="#" */}
        {/*     class="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" */}
        {/*   > */}
        {/*     100 */}
        {/*   </a> */}
        {/* </li> */}
        <li>
          <a
            href="#"
            class="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            onClick={() => {
              if (currentPage < totalPages) {
                onPageChange(currentPage + 1);
              }
            }}
          >
            <span class="sr-only">Next</span>
            <svg
              class="w-5 h-5"
              aria-hidden="true"
              fill="currentColor"
              viewbox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
