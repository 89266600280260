import { useState } from "react";

import { createContext, useContext } from "react";

const MenuVisibilityContext = createContext();

export const useMenuVisibility = () => {
  return useContext(MenuVisibilityContext);
};

export default function MenuProvider({ children }) {
  console.log("MenuProvider.children", children);
  const [isMenuVisible, setMenuVisible] = useState(true);

  return (
    <MenuVisibilityContext.Provider value={{ isMenuVisible, setMenuVisible }}>
      {children}
    </MenuVisibilityContext.Provider>
  );
}
