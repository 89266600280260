import React, { useEffect } from "react";
import { useApiService } from "./hooks/apiService";
import useUserStore from "./hooks/user-store";

const StoreInitializer = () => {
  const updateUserState = useUserStore((state) => state.updateUserState);
  const chatbotState = useUserStore((state) => state?.userData?.chatbot);
  const { apiService } = useApiService();

  async function fetchChatbotData() {
    let response = await apiService.getMyChatbotSettings();
    return response[0];
  }

  async function initializeState() {
    if (!chatbotState) {
      let chatbotData = await fetchChatbotData();
      await updateUserState({ chatbot: chatbotData });
    }
  }

  useEffect(() => {
    initializeState();
  }, [chatbotState]);

  return null;
};

export default StoreInitializer;
