import { Button, TextInput } from "flowbite-react";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITE_KEY } from "./constants";
import { Link } from "react-router-dom";
import ApiService from "./api";

function Newsletter() {
  const [email, setEmail] = useState("");
  // newsletterId should be a string
  const [newsletterId, setNewsletterId] = useState("1"); // Set this based on your newsletter logic
  const [message, setMessage] = useState("");
  const [showCaptcha, setShowCaptcha] = useState(true);
  const [success, setSuccess] = useState(false);
  const recaptchaRef = useRef(null);

  const handleSubscribe = async () => {
    const recaptchaValue = await recaptchaRef.current.executeAsync();

    if (!recaptchaValue) {
      // setMessage("Please verify you are not a robot.");
      setMessage("Please complete the CAPTCHA verification.");
      // setMessage(recaptchaValue);

      return;
    }

    try {
      const api = new ApiService();
      const response = await api.subscribe(email, newsletterId, recaptchaValue);
      setSuccess(true);
      recaptchaRef.current.reset(); // Reset reCAPTCHA
      setShowCaptcha(false);
    } catch (error) {
      setMessage("Could not subscribe, an error occurred");
      console.error(error.response);
    }
  };

  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="mx-auto max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16">
        <div className="items-center justify-between gap-16 rounded-lg bg-gray-900 p-6 text-white dark:bg-gray-800 md:p-12 lg:flex lg:gap-24">
          <div className="w-full">
            <h2 className="mb-4 text-3xl font-extrabold tracking-tight sm:text-4xl">
              Meld u aan voor onze nieuwsbrief
            </h2>
            <p className="text-gray-400 sm:text-xl">
              Abonneer u met uw e-mail op onze nieuwsbrief voor de laatste -
              updates, aankondigingen en exclusieve kortingen.
            </p>
          </div>
          <div className="mt-6 w-full lg:mt-0">
            <form action="#">
              <div className="mb-3 items-center space-y-4 sm:flex sm:space-y-0">
                {!success && (
                  <>
                    <div className="relative w-full">
                      <label
                        htmlFor="member_email"
                        className="mb-2 hidden text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Email address
                      </label>
                      <TextInput
                        icon={() => (
                          <svg
                            className="h-5 w-5 text-gray-500 dark:text-gray-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                          </svg>
                        )}
                        id="member_email"
                        name="member_email"
                        placeholder="Voer uw e-mailadres in"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        type="email"
                        className="md:[&_input]:rounded-r-none"
                      />
                    </div>
                    <div>
                      <Button
                        color="lime"
                        type="button"
                        className="w-full md:w-fit md:rounded-l-none"
                        onClick={handleSubscribe}
                      >
                        Subscribe
                      </Button>
                    </div>
                  </>
                )}
                {success && (
                  <div className="relative w-full">
                    <p className="text-primary-300">
                      Bedankt voor het aanmelden voor de nieuwsbrief, wij houden
                      u op de hoogte!
                    </p>
                  </div>
                )}
                {showCaptcha && (
                  <ReCAPTCHA
                    style={{ display: "inline-block" }}
                    sitekey={RECAPTCHA_SITE_KEY}
                    size="invisible"
                    ref={recaptchaRef}
                  />
                )}
                {message && (
                  <div className="relative w-full">
                    <p>{message}</p>
                  </div>
                )}
              </div>
              <div className="text-left text-sm text-gray-400 dark:text-gray-300">
                Wij hechten waarde aan de bescherming van uw gegevens.{" "}
                <Link
                  to="/privacy-beleid"
                  className="font-medium text-white hover:underline"
                >
                  Lees ons Privacybeleid
                </Link>
                .
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Newsletter;
