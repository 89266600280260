import { md5 } from "js-md5";

function emailToGravatar(email) {
  // gravatar link is made by stripping whitespace and converting to lowercase
  // then hashing the email with md5
  // then appending it to the gravatar url

  const hash = md5(email.trim().toLowerCase());
  return `https://www.gravatar.com/avatar/${hash}`;
}

export default emailToGravatar;
