export const printDateInUserLocale = (dateInput) => {
  let date;

  if (dateInput instanceof Date) {
    date = dateInput;
  } else if (typeof dateInput === "string") {
    // Parse the string format "YYYY-MM-DDTHH:mm:ss.SSSSSS"
    date = new Date(dateInput);

    if (isNaN(date.getTime())) {
      console.error("Invalid date string format");
      return;
    }
  } else {
    console.error(
      "Input must be a valid Date object or a date string",
      dateInput,
    );
    return;
  }

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    // timeZoneName: "short",
  };

  const localeDateString = date.toLocaleString(undefined, options);
  return localeDateString;
};
