import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { COMPANY_NAME } from "./constants";
import { Link, useNavigate } from "react-router-dom";
import myLogo from "/logo.png";
import { useMenuVisibility } from "./hooks/menu-visibility";
import { useForm } from "react-hook-form";
import CheckboxIconCircle from "./icons/CheckboxIconCircle";
import useTrialStore from "./hooks/trial-store";
import TrialMenu from "./TrialMenu";
import { useApiService } from "./hooks/apiService";

const TrialConfirm = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const { setMenuVisible } = useMenuVisibility();
  const trialRegistrationData = useTrialStore(
    (state) => state.trialRegistrationData,
  );
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const { apiService } = useApiService();
  const onSubmit = async (data) => {
    const userInput = { email: user.email };
    await apiService.startTrial(trialRegistrationData, userInput);
    // todo redirect to dashboard or something
    console.log(JSON.stringify(data));
    console.log(JSON.stringify(trialRegistrationData));
    navigate("/dashboard");
  };
  console.log(errors);

  useEffect(() => {
    setMenuVisible(false);
    return () => {
      setMenuVisible(true);
    };
  }, []);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (!isAuthenticated) {
    return loginWithRedirect({
      authorizationParams: {
        redirect_uri: `${window.location.origin}/trial`,
      },
    });
  }

  return (
    <>
      <section className="py-8 bg-white dark:bg-gray-900 lg:py-0">
        <div className="lg:flex">
          <TrialMenu siteLogo={myLogo}></TrialMenu>
          <div className="flex items-center mx-auto md:w-[42rem] px-4 md:px-8 xl:px-0">
            <div className="w-full">
              <div className="flex items-center justify-center mb-8 space-x-4 lg:hidden">
                <Link
                  to="/"
                  className="flex items-center text-2xl font-semibold"
                >
                  <img className="w-8 h-8 mr-2" src={myLogo} />
                  <span className="text-gray-900 dark:text-white">
                    {COMPANY_NAME}
                  </span>
                </Link>
                <Link
                  to="/prijzen"
                  className="inline-flex items-center text-sm font-medium text-primary-700 hover:text-white"
                >
                  <svg
                    className="w-6 h-6 mr-1"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  Terug
                </Link>
              </div>
              <ol className="flex items-center mb-6 text-sm font-medium text-center text-gray-500 dark:text-gray-400 lg:mb-12 sm:text-base">
                <li className="flex items-center sm:after:content-[''] after:w-12 after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <div className="flex items-center sm:block after:content-['/'] sm:after:hidden after:mx-2 after:font-light after:text-gray-200 dark:after:text-gray-500">
                    <div className="mr-2 sm:mb-2 sm:mx-auto">1</div>
                    Account{" "}
                    <span className="hidden sm:inline-flex">Details</span>
                  </div>
                </li>
                <li className="flex items-center after:content-[''] after:w-12 after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <div className="flex items-center sm:block after:content-['/'] sm:after:hidden after:mx-2 after:font-light after:text-gray-200 dark:after:text-gray-500">
                    <div className="mr-2 sm:mb-2 sm:mx-auto">2</div>
                    Configureer{" "}
                    <span className="hidden sm:inline-flex">Chat</span>
                  </div>
                </li>
                <li className="flex items-center text-primary-600 dark:text-primary-500 sm:block">
                  <CheckboxIconCircle></CheckboxIconCircle>
                  Bevestigen
                </li>
              </ol>
              <h1 className="mb-4 text-2xl font-extrabold tracking-tight text-gray-900 sm:mb-6 leding-tight dark:text-white">
                Bevestigen
              </h1>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="relative p-4 mb-6 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
                  <div className="flex justify-between mb-4 rounded-t sm:mb-5">
                    <div className="flex items-center">
                      <img
                        className="object-contain w-12 h-12 mr-2 rounded-full sm:mr-4"
                        src={trialRegistrationData.chatbotLogoUrl}
                        alt="Chatbot Logo"
                      ></img>
                      <div>
                        <h3 className="text-lg font-bold text-gray-900 md:text-xl dark:text-white">
                          {trialRegistrationData.chatbotName}
                        </h3>
                        <p className="text-base font-light text-gray-500 dark:text-gray-400">
                          {trialRegistrationData.companyName}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="grid gap-4 mb-4 sm:grid-cols-2 sm:gap-6 sm:mb-5">
                    <dl>
                      {/* <dt className="sr-only">Role</dt> */}
                      {/* <dd className="flex items-center mb-2 font-light text-gray-500 dark:text-gray-400"> */}
                      {/*   <svg */}
                      {/*     className="w-4 h-4 mr-1.5 text-gray-400 dark:text-gray-500" */}
                      {/*     aria-hidden="true" */}
                      {/*     fill="currentColor" */}
                      {/*     viewBox="0 0 20 20" */}
                      {/*     xmlns="http://www.w3.org/2000/svg" */}
                      {/*   > */}
                      {/*     <path */}
                      {/*       fillRule="evenodd" */}
                      {/*       d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z" */}
                      {/*       clipRule="evenodd" */}
                      {/*     ></path> */}
                      {/*     <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z"></path> */}
                      {/*   </svg> */}
                      {/*   Frontend Developer */}
                      {/* </dd> */}
                      <dt className="sr-only">Location</dt>
                      <dd className="flex items-center mb-4 font-light text-gray-500 dark:text-gray-400 sm:mb-5">
                        <svg
                          className="w-4 h-4 mr-1.5 text-gray-400 dark:text-gray-500"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        {trialRegistrationData.country}
                      </dd>
                      <dt className="mb-2 leading-none text-gray-500 dark:text-gray-400">
                        Email Adress
                      </dt>
                      <dd className="mb-4 font-medium text-gray-900 sm:mb-5 dark:text-white">
                        {user.email}
                      </dd>
                      {/* <dt className="mb-2 leading-none text-gray-500 dark:text-gray-400"> */}
                      {/*   Home Adress */}
                      {/* </dt> */}
                      {/* <dd className="mb-4 font-medium text-gray-900 sm:mb-5 dark:text-white"> */}
                      {/*   92 Miles Drive, Newark, NJ 07103, California, United */}
                      {/*   States of America */}
                      {/* </dd> */}
                      {/* <dt className="mb-2 leading-none text-gray-500 dark:text-gray-400"> */}
                      {/*   Phone Number */}
                      {/* </dt> */}
                      {/* <dd className="font-medium text-gray-900 dark:text-white"> */}
                      {/*   +1234 567 890 / +12 345 678{" "} */}
                      {/* </dd> */}
                    </dl>
                    <dl>
                      <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">
                        Proefperiode
                      </dt>
                      <dd className="mb-4 font-light text-gray-500 sm:mb-5 dark:text-gray-400">
                        {/* <pre> */}
                        {/*   {JSON.stringify(trialRegistrationData, null, 2)} */}
                        {/* </pre> */}
                        Zodra u op Start proefperiode klikt, wordt uw chatbot
                        aangemaakt en worden de instructies getoond waarmee u de
                        chatbot op uw eigen site kunt integreren. Bedankt voor
                        uw interesse in {COMPANY_NAME}!
                      </dd>
                      {/* <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white"> */}
                      {/*   Social */}
                      {/* </dt> */}
                      {/* <dd className="inline-flex items-center space-x-1"> */}
                      {/*   <a */}
                      {/*     href="#" */}
                      {/*     className="p-2 text-gray-500 rounded-lg hover:text-gray-900 dark:text-gray-400 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-700" */}
                      {/*   > */}
                      {/*     <svg */}
                      {/*       className="w-5 h-5" */}
                      {/*       fill="currentColor" */}
                      {/*       viewBox="0 0 24 24" */}
                      {/*       aria-hidden="true" */}
                      {/*     > */}
                      {/*       <path */}
                      {/*         fillRule="evenodd" */}
                      {/*         d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" */}
                      {/*         clipRule="evenodd" */}
                      {/*       /> */}
                      {/*     </svg> */}
                      {/*   </a> */}
                      {/*   <a */}
                      {/*     href="#" */}
                      {/*     className="p-2 text-gray-500 rounded-lg hover:text-gray-900 dark:text-gray-400 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-700" */}
                      {/*   > */}
                      {/*     <svg */}
                      {/*       className="w-5 h-5" */}
                      {/*       fill="currentColor" */}
                      {/*       viewBox="0 0 24 24" */}
                      {/*       aria-hidden="true" */}
                      {/*     > */}
                      {/*       <path */}
                      {/*         fillRule="evenodd" */}
                      {/*         d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" */}
                      {/*         clipRule="evenodd" */}
                      {/*       /> */}
                      {/*     </svg> */}
                      {/*   </a> */}
                      {/*   <a */}
                      {/*     href="#" */}
                      {/*     className="p-2 text-gray-500 rounded-lg hover:text-gray-900 dark:text-gray-400 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-700" */}
                      {/*   > */}
                      {/*     <svg */}
                      {/*       className="w-5 h-5" */}
                      {/*       fill="currentColor" */}
                      {/*       viewBox="0 0 24 24" */}
                      {/*       aria-hidden="true" */}
                      {/*     > */}
                      {/*       <path */}
                      {/*         fillRule="evenodd" */}
                      {/*         d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" */}
                      {/*         clipRule="evenodd" */}
                      {/*       /> */}
                      {/*     </svg> */}
                      {/*   </a> */}
                      {/*   <a */}
                      {/*     href="#" */}
                      {/*     className="p-2 text-gray-500 rounded-lg hover:text-gray-900 dark:text-gray-400 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-700" */}
                      {/*   > */}
                      {/*     <svg */}
                      {/*       className="w-5 h-5" */}
                      {/*       fill="currentColor" */}
                      {/*       viewBox="0 0 24 24" */}
                      {/*       aria-hidden="true" */}
                      {/*     > */}
                      {/*       <path */}
                      {/*         fillRule="evenodd" */}
                      {/*         d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z" */}
                      {/*         clipRule="evenodd" */}
                      {/*       /> */}
                      {/*     </svg> */}
                      {/*   </a> */}
                      {/* </dd> */}
                    </dl>
                  </div>
                </div>

                <div className="flex space-x-3">
                  <Link
                    to="/trial/setup-chat"
                    className="text-center items-center w-full py-2.5 sm:py-3.5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                  >
                    Vorige
                  </Link>
                  <button
                    type="submit"
                    className="w-full text-white bg-primary-700 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 sm:py-3.5 text-center dark:bg-primary-700 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  >
                    Start proefperiode!
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TrialConfirm;
