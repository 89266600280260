import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { COMPANY_NAME } from "./constants";
import { Link, useNavigate } from "react-router-dom";
import myLogo from "/logo.png";
import { useMenuVisibility } from "./hooks/menu-visibility";
import CheckboxIcon from "./icons/CheckboxIcon";
import { useForm } from "react-hook-form";
import CheckboxIconCircle from "./icons/CheckboxIconCircle";
import useTrialStore from "./hooks/trial-store";
import FileUploader from "./FileUploader";
import TrialMenu from "./TrialMenu";

const Trial2 = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const { setMenuVisible } = useMenuVisibility();
  const updateTrialRegistrationData = useTrialStore(
    (state) => state.updateTrialRegistrationData,
  );
  const trialRegistrationData = useTrialStore(
    (state) => state.trialRegistrationData,
  );
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: trialRegistrationData });
  register("chatbotLogoUrl");
  const chatbotLogoUrl = watch("chatbotLogoUrl");
  // console.log("chatbotLogoUrl", chatbotLogoUrl);
  const chatbotName = watch("chatbotName");
  // console.log("chatbotName", chatbotName);
  const defaultLogoUrl = `https://images.placeholders.dev/?width=300&height=300&text=${chatbotName || "your logo"}&bgColor=%23f7f6f6&textColor=%236d6e71&textWrap=true`;

  const navigate = useNavigate();

  const onSubmit = (data) => {
    // console.log(data, JSON.stringify(data));
    if (!data.chatbotLogoUrl) {
      data.chatbotLogoUrl = defaultLogoUrl;
    }
    updateTrialRegistrationData(data);
    navigate("/trial/confirm");
  };

  if (errors.length) {
    console.log(`errors: ${JSON.stringify(errors, null, 4)}`);
  }

  useEffect(() => {
    setMenuVisible(false);
    return () => {
      setMenuVisible(true);
    };
  }, []);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (!isAuthenticated) {
    return loginWithRedirect({
      authorizationParams: {
        redirect_uri: `${window.location.origin}/trial`,
      },
    });
  }

  return (
    <>
      <section className="py-8 bg-white dark:bg-gray-900 lg:py-0">
        <div className="lg:flex">
          <TrialMenu siteLogo={myLogo}></TrialMenu>
          <div className="flex items-center mx-auto md:w-[42rem] px-4 md:px-8 xl:px-0">
            <div className="w-full">
              <div className="flex items-center justify-center mb-8 space-x-4 lg:hidden">
                <a
                  href="#"
                  className="flex items-center text-2xl font-semibold"
                >
                  <img className="w-8 h-8 mr-2" src={myLogo} />
                  <span className="text-gray-900 dark:text-white">
                    {COMPANY_NAME}
                  </span>
                </a>
              </div>
              <ol className="flex items-center mb-6 text-sm font-medium text-center text-gray-500 dark:text-gray-400 lg:mb-12 sm:text-base">
                <li className="flex items-center sm:after:content-[''] after:w-12 after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <div className="flex items-center sm:block after:content-['/'] sm:after:hidden after:mx-2 after:font-light after:text-gray-200 dark:after:text-gray-500">
                    <div className="mr-2 sm:mb-2 sm:mx-auto">1</div>
                    Account{" "}
                    <span className="hidden sm:inline-flex">Details</span>
                  </div>
                </li>
                <li className="flex items-center text-primary-600 dark:text-primary-500 after:content-[''] after:w-12 after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                  <div className="flex items-center sm:block after:content-['/'] sm:after:hidden after:mx-2 after:font-light after:text-gray-200 dark:after:text-gray-500">
                    <CheckboxIconCircle></CheckboxIconCircle>
                    Configureer{" "}
                    <span className="hidden sm:inline-flex">Chat</span>
                  </div>
                </li>
                <li className="flex items-center sm:block">
                  <div className="mr-2 sm:mb-2 sm:mx-auto">3</div>
                  Bevestigen
                </li>
              </ol>
              <h1 className="mb-4 text-2xl font-extrabold tracking-tight text-gray-900 sm:mb-6 leding-tight dark:text-white">
                Configureer Chat
              </h1>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid gap-5 my-6 sm:grid-cols-1">
                  <div>
                    <label
                      htmlFor="chatbot-name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Chatbot Naam
                    </label>
                    <input
                      type="text"
                      name="chatbot-name"
                      id="chatbot-name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      placeholder="Website Assistant"
                      required={true}
                      defaultValue="Bedrijfsnaam Website Assistant"
                      {...register("chatbotName", { required: true })}
                    ></input>
                    {/* errors obj contains details info on what the actual error is, todo replace required error message with actual error message */}
                    {errors.fullname && <span>Dit veld is verplicht</span>}
                  </div>
                  <div>
                    <label
                      htmlFor="chatbot-logo"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Chatbot Logo
                    </label>
                    <FileUploader
                      id="chatbot-logo"
                      required={false}
                      defaultUrl={defaultLogoUrl}
                      fileUrl={chatbotLogoUrl}
                      setUrlCallback={(logoUrl) => {
                        console.log(`setting chatbotLogoUrl: ${logoUrl}`);
                        setValue("chatbotLogoUrl", logoUrl);
                      }}
                    ></FileUploader>
                    <p
                      className="mt-1 text-sm text-gray-500 dark:text-gray-300"
                      id="file_input_help"
                    >
                      SVG, PNG, JPG, WEBP or GIF (MAX. 1MB).
                    </p>
                  </div>
                  {/* <div> */}
                  {/*   <label */}
                  {/*     htmlFor="phone-number" */}
                  {/*     className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" */}
                  {/*   > */}
                  {/*     Phone */}
                  {/*   </label> */}
                  {/*   <input */}
                  {/*     type="number" */}
                  {/*     name="phone-number" */}
                  {/*     id="phone-number" */}
                  {/*     className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" */}
                  {/*     placeholder="+123 567 890" */}
                  {/* required={true} */}
                  {/*   ></input> */}
                  {/* </div> */}
                </div>
                <div className="mb-6 space-y-3">
                  <div className="flex items-start">
                    <div className="ml-3 text-sm">
                      U kunt later op elk moment alle instellingen wijzigen.
                    </div>
                  </div>
                </div>
                <div className="flex space-x-3">
                  <Link
                    to="/trial"
                    className="text-center items-center w-full py-2.5 sm:py-3.5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                  >
                    Vorige
                  </Link>
                  <button
                    type="submit"
                    className="w-full text-white bg-primary-700 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 sm:py-3.5 text-center dark:bg-primary-700 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  >
                    Volgende
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Trial2;
