import axios from "axios";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * @typedef {Object} TrialInput
 * @property {string} companyName
 * @property {string} chatbotName
 * @property {string} chatbotLogoUrl
 * @property {string} fullname
 * @property {string} country
 * @property {boolean} agreeToTerms
 * @property {boolean} subscribeToNewsletter
 */

/**
 * @typedef {Object} UserInput
 * @property {string} email
 */

/**
 * @typedef {Object} StartTrialInput
 * @property {TrialInput} trial
 * @property {UserInput} user
 */
const BASE_URL = import.meta.env.VITE_API_BASE_URL;

class ApiService {
  /**
   * Constructs a new ApiService instance.
   * @param {string} authToken - The authentication token.
   */
  constructor(baseUrl = BASE_URL, authToken = null) {
    if (baseUrl === null) {
      baseUrl = BASE_URL;
    }
    this.baseUrl = baseUrl;
    this.authToken = authToken;
    this.tokenCallback = null;
  }

  setFetchTokenCallback(tokenCallback) {
    this.tokenCallback = tokenCallback;
  }

  async autoFetchToken() {
    if (!this.authToken && this.tokenCallback) {
      const token = await this.tokenCallback();
      this.setAuthToken(token);
    }
  }

  setAuthToken(authToken) {
    this.authToken = authToken;
  }

  /**
   * Handles the API response.
   * @template T
   * @param {Promise<AxiosResponse<T>>} response - The API response.
   * @returns {T} The response data.
   */
  handleResponse(response) {
    return response.data.data;
  }

  /**
   * Handles API errors.
   * @param {AxiosError} error - The API error.
   */
  handleError(error) {
    console.error(error);
    console.error("API Error", error.message);
    console.error("Stack trace", error.stack);
    if (error?.response?.data?.data?.error) {
      throw new Error(error.response.data.data.error);
    } else {
      throw error;
    }
  }

  /**
   * Makes an API request.
   * @template T
   * @param {string} method - The HTTP method (e.g., 'get', 'post', 'delete').
   * @param {string} url - The URL for the API request.
   * @param {*} [params] - The request params (optional).
   * @param {*} [payload] - The request payload (optional).
   * @returns {Promise<T>} A promise that resolves with the response data.
   */
  async makeRequest(
    method,
    url,
    payload = null,
    params = null,
    requiresAuth = true,
    responseHandler = null,
  ) {
    // await sleep(500);
    try {
      let config = {};
      if (params) {
        console.log(params, config);
        config = { ...config, ...params };
        console.log(config);
      }
      if (requiresAuth) {
        await this.autoFetchToken();
        if (!config.headers) {
          config.headers = {};
        }
        config.headers.Authorization = `Bearer ${this.authToken}`;
      }
      let response;
      switch (method) {
        case "get":
          response = await axios.get(this.baseUrl + url, config);
          break;
        case "post":
          response = await axios.post(this.baseUrl + url, payload, config);
          break;
        case "put":
          response = await axios.put(this.baseUrl + url, payload, config);
          break;
        case "delete":
          response = await axios.delete(this.baseUrl + url, config);
          break;
        default:
          console.error("Unsupported HTTP method");
          throw new Error("Unsupported HTTP method");
      }
      let handlerFunc = responseHandler || this.handleResponse;
      console.log(handlerFunc);
      return handlerFunc(response);
    } catch (error) {
      this.handleError(error);
    }
  }

  /**
   * Makes a POST request to subscribe to a newsletter.
   * @param {string} email - The email address to subscribe.
   * @param {string} newsletterId - The newsletter ID.
   * @param {string} recaptchaValue - The reCAPTCHA value.
   * @returns {Promise<any>} A promise that resolves with the response data.
   */
  async subscribe(email, newsletterId, recaptchaValue) {
    const res = await this.makeRequest(
      "post",
      "/subscribe",
      {
        email,
        newsletter_id: newsletterId,
        recaptchaValue,
      },
      { validateStatus: (status) => status < 500 },
      false,
      (response) => {
        return response.data;
      },
    );

    if (res.status === 400) {
      if (res.message === "Subscriber already exists") {
        return res;
      } else {
        console.error(res);
        throw new Error(res.message);
      }
    }

    return res;
  }

  /**
   * Method for starting a trial.
   * @param {TrialInput} trialInput - The trial input data.
   * @param {UserInput} userInput - The user input data.
   * @returns {Promise<any>} A promise that resolves with the response data.
   */
  async startTrial(trialInput, userInput) {
    const payload = {
      trial: trialInput,
      user: userInput,
    };
    return this.makeRequest("post", "/start_trial", payload);
  }

  /**
   * Method for checking if the user has a trial.
   * @returns {Promise<any>} A promise that resolves with the response data.
   * @memberof ApiService
   *
   */
  async hasTrial() {
    return this.makeRequest("get", `/has_trial`);
  }

  /**
   * Uploads a file to the server and returns the logo URL.
   * @param {File} file - The file to upload.
   * @returns {Promise<string>} A promise that resolves with the logo URL.
   */
  async uploadFile(file) {
    console.log(file);
    const formData = new FormData();
    formData.append("file", file);
    const params = {
      headers: { "Content-Type": "multipart/form-data" },
    };
    const data = await this.makeRequest("post", "/add_logo", formData, params);
    console.log("data", data);
    return data;
  }

  /**
   * Gets chatbot settings.
   * @param {string} chatbotId - The chatbot ID.
   * @returns {Promise<any>} A promise that resolves with the response data.
   * @memberof ApiService
   */
  async getChatbotSettings(chatbotId) {
    if (!chatbotId) {
      throw new Error("chatbotId is required");
    }
    return this.makeRequest("get", `/chatbots/${chatbotId}`);
  }

  async getMyChatbotSettings() {
    return this.makeRequest("get", `/chatbots/user/me`);
  }

  /**
   * Updates chatbot settings.
   * @param {string} chatbotId - The chatbot ID.
   * @param {any} chatbotData - The chatbot data.
   * @returns {Promise<any>} A promise that resolves with the response data.
   * @memberof ApiService
   */
  async updateChatbotSettings(chatbotId, chatbotData) {
    if (!chatbotId) {
      throw new Error("chatbotId is required");
    }
    return this.makeRequest("put", `/chatbots/${chatbotId}`, chatbotData);
  }

  /**
   * Gets a secured endpoint.
   * @returns {Promise<any>} A promise that resolves with the response data.
   * @memberof ApiService
   */
  async getSecuredEndpoint() {
    return this.makeRequest("get", "/secured");
  }

  /**
   * Gets chatbot conversations.
   * @param {string} chatbotUUID - The chatbot UUID.
   * @returns {Promise<any>} A promise that resolves with the response data.
   * @memberof ApiService
   */
  async getChatbotConversations(chatbotUUID, currentPage, pageSize = 25) {
    if (!chatbotUUID) {
      throw new Error("chatbotUUID is required");
    }
    return this.makeRequest(
      "get",
      `/chatbots/${chatbotUUID}/conversations`,
      null,
      {
        params: { page: currentPage, page_size: pageSize },
      },
    );
  }

  /**
   * Gets chatbot conversation.
   * @param {string} chatbotUUID - The chatbot UUID.
   * @param {string} conversationId - The conversation ID.
   * @returns {Promise<any>} A promise that resolves with the response data.
   * @memberof ApiService
   */
  async getChatbotConversation(chatbotUUID, conversationId) {
    if (!chatbotUUID) {
      throw new Error("chatbotUUID is required");
    }
    if (!conversationId) {
      throw new Error("conversationId is required");
    }
    return this.makeRequest(
      "get",
      `/chatbots/${chatbotUUID}/conversations/${conversationId}`,
    );
  }

  /**
   * Gets chatbot offerings.
   * @param {string} chatbotUUID - The chatbot UUID.
   * @returns {Promise<any>} A promise that resolves with the response data.
   * @memberof ApiService
   */
  async getChatbotOfferings(chatbotUUID) {
    if (!chatbotUUID) {
      throw new Error("chatbotUUID is required");
    }
    return this.makeRequest("get", `/chatbots/${chatbotUUID}/offerings`);
  }

  /**
   * Gets a chatbot offering.
   * @param {string} chatbotUUID - The chatbot UUID.
   * @param {string} offeringId - The offering ID.
   * @returns {Promise<any>} A promise that resolves with the response data.
   * @memberof ApiService
   */
  async getOffering(chatbotUUID, offeringId) {
    if (!chatbotUUID) {
      throw new Error("chatbotUUID is required");
    }
    if (!offeringId) {
      throw new Error("offeringId is required");
    }
    return this.makeRequest(
      "get",
      `/chatbots/${chatbotUUID}/offerings/${offeringId}`,
    );
  }

  /**
   * Deletes an offering.
   * @param {string} chatbotUUID - The chatbot UUID.
   * @param {Object} offeringData - The offering ID.
   * @returns {Promise<any>} A promise that resolves with the response data.
   * @memberof ApiService
   */
  async addOffering(chatbotUUID, offeringData) {
    if (!chatbotUUID) {
      throw new Error("chatbotUUID is required");
    }
    return this.makeRequest(
      "post",
      `/chatbots/${chatbotUUID}/offerings`,
      offeringData,
    );
  }

  /**
   * Deletes an offering.
   * @param {string} chatbotUUID - The chatbot UUID.
   * @param {string} offeringId - The offering ID.
   * @returns {Promise<any>} A promise that resolves with the response data.
   * @memberof ApiService
   */
  async deleteOffering(chatbotUUID, offeringId) {
    if (!chatbotUUID) {
      throw new Error("chatbotUUID is required");
    }
    if (!offeringId) {
      throw new Error("offeringId is required");
    }
    return this.makeRequest(
      "delete",
      `/chatbots/${chatbotUUID}/offerings/${offeringId}`,
    );
  }

  /**
   * Updates an offering.
   * @param {string} chatbotUUID - The chatbot UUID.
   * @param {string} offeringId - The offering ID.
   * @param {Object} offeringData - The offering data.
   * @returns {Promise<any>} A promise that resolves with the response data.
   * @memberof ApiService
   */
  async updateOffering(chatbotUUID, offeringId, offeringData) {
    if (!chatbotUUID) {
      throw new Error("chatbotUUID is required");
    }
    if (!offeringId) {
      throw new Error("offeringId is required");
    }
    return this.makeRequest(
      "put",
      `/chatbots/${chatbotUUID}/offerings/${offeringId}`,
      offeringData,
    );
  }

  /**
   * Gets chatbot leads.
   * @param {string} chatbotUUID - The chatbot UUID.
   * @returns {Promise<any>} A promise that resolves with the response data.
   * @memberof ApiService
   */
  async getLeads(chatbotUUID, currentPage, pageSize = 25) {
    if (!chatbotUUID) {
      throw new Error("chatbotUUID is required");
    }
    return this.makeRequest("get", `/chatbots/${chatbotUUID}/leads`, null, {
      params: { page: currentPage, page_size: pageSize },
    });
  }
}

export default ApiService;
