import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const useTrialStore = create(
  persist(
    (set) => ({
      trialRegistrationData: {
        fullname: null,
        companyName: null,
        country: null,
        agreeToTerms: true,
        subscribeToNewsletter: true,
        chatbotName: null,
        chatbotLogoUrl: null,
      },
      updateTrialRegistrationData: (newData) =>
        set((state) => ({
          trialRegistrationData: { ...state.trialRegistrationData, ...newData },
        })),
      resetTrialRegistrationData: () => set({ trialRegistrationData: {} }),
    }),
    { name: "trial-store", storage: createJSONStorage(() => sessionStorage) },
  ),
);

export default useTrialStore;
