import React from "react";
import { Link } from "react-router-dom";

export default function Billing() {
  return (
    <div>
      <div class="bg-white rounded shadow p-6">
        <h2 class="text-2xl font-bold mb-4">Status Proefperiode</h2>
        <p>
          U bevindt zich momenteel in een{" "}
          <strong>14-daagse proefperiode</strong>.{" "}
          <span class="text-green-600">Nog 12 dagen</span> over.
        </p>
      </div>

      <div class="bg-white rounded shadow p-6 mt-4">
        <h2 class="text-2xl font-bold mb-4">Aankomende Factuur</h2>
        <p>
          Uw eerste factuur wordt gegenereerd na afloop van de proefperiode.
        </p>
        <ul class="list-disc list-inside">
          <li>
            Abonnementsplan: <strong>Pro Plan</strong>
          </li>
          <li>
            Maandelijkse Kosten: <strong>€49,99</strong>
          </li>
          <li>
            Factuurdatum: <strong>15e van elke maand</strong>
          </li>
        </ul>
      </div>

      <div class="bg-white rounded shadow p-6 mt-4">
        <h2 class="text-2xl font-bold mb-4">Vroegtijdig Abonneren</h2>
        <p>
          Als u nu klaar bent om te abonneren, kunt u{" "}
          <a href="#" class="text-blue-600">
            hier uw plan kiezen
          </a>
          .
        </p>
      </div>

      <div class="bg-white rounded shadow p-6 mt-4">
        <h2 class="text-2xl font-bold mb-4">Hulp Nodig?</h2>
        <p>
          Als u vragen heeft over facturering of uw account, neem dan{" "}
          <a
            className="text-blue-600 hover:underline"
            href="mailto:support@sitechatpro.nl"
          >
            contact met ons op
          </a>
          .
        </p>
      </div>

      <div class="bg-white rounded shadow p-6 mt-4">
        <h2 class="text-2xl font-bold mb-4">FAQ</h2>
        <p>
          Bekijk onze{" "}
          <Link to="/dashboard/faq" class="text-blue-600">
            Veelgestelde Vragen
          </Link>{" "}
          over facturering en abonnementen.
        </p>
      </div>
    </div>
  );
}
