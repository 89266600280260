import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";

const Login = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const location = useLocation();
  const from = location.state?.from || "/dashboard";

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  loginWithRedirect({
    appState: {
      returnTo: from,
    },
    authorizationParams: {
      screen_hint: "signup",
    },
  });

  return <div>Redirecting to login...</div>;
};

export default Login;
