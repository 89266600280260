import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "flowbite-react";
import { Link, useNavigate } from "react-router-dom";

function PricingItem({ children }) {
  return (
    <li className="flex space-x-2.5">
      <svg
        className="h-5 w-5 flex-shrink-0 text-green-500 dark:text-green-400"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clipRule="evenodd"
        />
      </svg>
      <span className="leading-tight text-gray-500 dark:text-gray-400">
        {children}
      </span>
    </li>
  );
}

function Pricing() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const handleStartTrialPeriod = () => {
    if (!isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: "/trial",
        },
        authorizationParams: {
          screen_hint: "signup",
        },
      });
    } else {
      navigate("/trial");
    }
  };
  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="mx-auto max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16">
        <div className="rounded-lg bg-white shadow dark:bg-gray-800 lg:grid lg:grid-cols-3">
          <div className="col-span-2 p-6 lg:p-8">
            <h2 className="mb-1 text-2xl font-bold text-gray-900 dark:text-white">
              Pricing built for all businesses.
            </h2>
            <p className="text-lg text-gray-500 dark:text-gray-400">
              Best for large scale uses and extended redistribution rights.
            </p>
            <div className="mt-4 grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:mt-6">
              <ul className="space-y-4 dark:text-white">
                <PricingItem>LLM site generation</PricingItem>
                <PricingItem>Chat Support</PricingItem>
                <PricingItem>Custom Branding</PricingItem>
                <PricingItem>Custom pricing</PricingItem>
              </ul>
              <ul className="hidden space-y-4 dark:text-white sm:block">
                <PricingItem>GPT-3.5</PricingItem>
                <PricingItem>GPT-4</PricingItem>
                <PricingItem>Generate leads</PricingItem>
                <PricingItem>Wordpress plugin</PricingItem>
              </ul>
              <ul className="hidden space-y-4 dark:text-white lg:block">
                <PricingItem>Chats opslaan</PricingItem>
                <PricingItem>Chats hervatten</PricingItem>
                <PricingItem>Prompt customization</PricingItem>
                <PricingItem>Mollie integratie</PricingItem>
              </ul>
            </div>
          </div>
          <div className="flex bg-gray-50 p-6 text-center dark:bg-gray-700 lg:p-8">
            <div className="w-full self-center justify-center">
              <div className="text-light mb-4 mt-1 text-gray-500 dark:text-gray-400">
                14-dagen proefperiode
              </div>
              <div className="text-5xl font-extrabold text-gray-900 dark:text-white">
                €399
              </div>
              <div className="text-light mb-4 mt-1 text-gray-500 dark:text-gray-400">
                per maand
              </div>
              <Button className="mx-auto" onClick={handleStartTrialPeriod}>
                Proefperiode Starten
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Pricing;
