import React from "react";
import useUserStore from "./hooks/user-store";

const Documentation = () => {
  const chatbotUuid = useUserStore((state) => state?.userData?.chatbot?.uuid);
  return (
    <div>
      <div className="bg-white rounded shadow p-6">
        <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">
          Technische Documentatie
        </h2>
        <h3 className="text-lg font-bold text-gray-900 dark:text-white">
          Hoe integreer ik de chatbot in mijn website?
        </h3>
        <p className="mt-2 text-gray-600 dark:text-gray-400">
          De chatbot kan eenvoudig worden geïntegreerd in uw website door de
          chatbot code toe te voegen aan de body-tag van uw website:{" "}
        </p>
        <pre className="mt-4 p-4 bg-gray-100 dark:bg-gray-800 rounded text-sm text-gray-600 dark:text-gray-400">
          <code>
            {`<div id="sitechatpro"></div>
<script crossorigin type="module" src="https://cdn.sitechatpro.nl/sitechatpro.js"></script>
<script>
    window.onload = function() {
        sitechatpro({containerId: "sitechatpro", chatbotId: "${chatbotUuid}"});
    };
</script>`}
          </code>
        </pre>

        <h3 className="text-lg font-bold text-gray-900 dark:text-white mt-6">
          Hoe kan ik de chatbot op specifieke plek op mijn website tonen?
        </h3>
        <p className="mt-2 text-gray-600 dark:text-gray-400">
          De tag <code>{`<div id="sitechatpro"></div>`}</code> is de plek waar
          de chatbot zal worden weergegeven. U kunt deze div verplaatsen naar de
          gewenste locatie op uw website. Het maakt niet uit of deze div zich
          voor of na de script tags bevindt.
        </p>
        <h3 className="text-lg font-bold text-gray-900 dark:text-white mt-6">
          Kan ik help krijgen bij het plaatsen van de chatbot code op mijn
          website?
        </h3>
        <p className="mt-2 text-gray-600 dark:text-gray-400">
          Ja, wij helpen u graag met het plaatsen van de chatbot code op uw
          website. Neem contact met ons door een e-mail naar{" "}
          <a
            className="text-blue-600 hover:underline"
            target="_blank"
            rel="noreferrer"
            href="mailto:support@sitechatpro.nl"
          >
            support@sitechatpro.nl
          </a>{" "}
          te sturen.
        </p>
      </div>
    </div>
  );
};

export default Documentation;
