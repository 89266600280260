import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useUserStore from "./hooks/user-store";
import { useApiService } from "./hooks/apiService";

const AssistantMessage = ({ content }) => (
  <li className="text-blue-500 text-left border-l-4 border-blue-500 pl-2">
    <span className="font-bold">Assistant:</span> {content}
  </li>
);

const UserMessage = ({ content }) => (
  <li className="text-green-500 text-right border-r-4 border-green-500 pr-2">
    <span className="font-bold">User:</span> {content}
  </li>
);

const FunctionCall = ({ name, content }) => (
  <li className="text-red-500 text-left border-r-4 border-red-500 pr-2">
    <span className="font-bold">GPT function call:</span> {name}({content})
  </li>
);

const Chat = () => {
  const { conversationId } = useParams();
  const navigate = useNavigate();
  const chatbotUuid = useUserStore((state) => state?.userData?.chatbot?.uuid);
  const { apiService } = useApiService();
  const [messages, setMessages] = useState([]);

  console.log(`chatbotUuid: ${chatbotUuid} from Chat`);

  useEffect(() => {
    if (chatbotUuid && conversationId) {
      const getConversation = async () => {
        const conversationResponse = await apiService.getChatbotConversation(
          chatbotUuid,
          conversationId,
        );
        console.log(conversationResponse);
        setMessages(conversationResponse.messages);
      };
      getConversation();
    }
  }, [chatbotUuid, conversationId]);

  const renderMessage = (msg) => {
    const msgData = JSON.parse(msg.message);
    const { role, content } = msgData;

    switch (role) {
      case "assistant":
        return <AssistantMessage key={msg.message_id} content={content} />;
      case "user":
        return <UserMessage key={msg.message_id} content={content} />;
      // case "system":
      case "function":
        const { name } = msgData;
        return (
          <FunctionCall key={msg.message_id} name={name} content={content} />
        );
      // Add more cases for additional roles
      // case 'system':
      //   return <SystemMessage key={msg.message_id} content={content} />;
      default:
        console.log(`Unsupported role: ${role}`);
        return null; // Skip rendering for unsupported roles
    }
  };

  return (
    <div>
      <button
        className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        onClick={() => {
          navigate(-1);
        }}
      >
        Back to Chats
      </button>
      <h1
        className="text-2xl font-bold text-gray-800 dark:text-gray-100 my-4"
        title={`${conversationId} ${chatbotUuid}`}
      >
        Chat {conversationId}
      </h1>
      {/* <p>Chat ID: {conversationId}</p> */}
      {/* <p>Chatbot UUID: {chatbotUuid}</p> */}
      <ul className="*:my-2 *:py-1">
        {messages && messages.map(renderMessage)}
      </ul>
    </div>
  );
};

export default Chat;
