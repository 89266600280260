import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, useLocation } from "react-router-dom";

function withRequiresAuth(WrappedComponent) {
  function ComponentWithState(props) {
    const { isAuthenticated, isLoading } = useAuth0();
    let location = useLocation();

    if (isLoading) {
      return <div>Loading ...</div>;
    }
    console.log("with Requires Auth", isAuthenticated);
    if (!isAuthenticated) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return <WrappedComponent {...props} />;
  }
  return ComponentWithState;
}

export default withRequiresAuth;
