import React from "react";
import { useState } from "react";
import FileUploader from "./FileUploader";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import withRequiresAuth from "./hoc/requires-auth";
import { Link, useNavigate } from "react-router-dom";
import ReadonlyCopyInput from "./components/CopyInput";
import { useApiService } from "./hooks/apiService";

function Settings() {
  const navigate = useNavigate();
  const [chatbotUUID, setChatbotUUID] = useState("");
  const { apiService } = useApiService();

  async function getChatbotSettings() {
    let response = await apiService.getMyChatbotSettings();
    console.log("chatbot settings:", response);
    if (response.length === 0) {
      navigate("/trial");
    } else {
      const firstChatbot = response[0];
      setChatbotUUID(firstChatbot.uuid);
      const chatbotData = {
        chatbotName: firstChatbot.name,
        chatbotLogoUrl: firstChatbot.logo_url,
        description: firstChatbot.description,
        welcomeMessage: firstChatbot.welcome_message,
      };
      console.log(chatbotData);
      return chatbotData;
    }
  }
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: async () => getChatbotSettings(),
  });
  const onSubmit = async (data) => {
    const chatbotData = {
      name: data.chatbotName,
      logo_url: data.chatbotLogoUrl,
      description: data.description,
      welcome_message: data.welcomeMessage,
    };
    toast.promise(apiService.updateChatbotSettings(chatbotUUID, chatbotData), {
      pending: "Updating chatbot settings... 🤖",
      success: "Chatbot settings updated successfully 🎉",
      error: "Failed to update chatbot settings 😢",
    });
  };

  const chatbotLogoUrl = watch("chatbotLogoUrl");
  // console.log("chatbotLogoUrl", chatbotLogoUrl);
  const chatbotName = watch("chatbotName");
  // console.log("chatbotName", chatbotName);
  const defaultLogoUrl = `https://images.placeholders.dev/?width=300&height=300&text=${chatbotName || "your logo"}&bgColor=%23f7f6f6&textColor=%236d6e71&textWrap=true`;

  return (
    <div>
      <div className="bg-white rounded shadow p-6">
        <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">
          Chatbot Instellingen
        </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid gap-4 mb-4 sm:grid-cols-2 sm:gap-6 sm:mb-5">
            <div className="sm:col-span-2">
              <label
                htmlFor="chatbotUuid"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Chatbot ID (readonly)
              </label>
              <ReadonlyCopyInput id="chatbotUuid" value={chatbotUUID} />
              <label
                htmlFor="chatbotUuid"
                className="block mt-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                <Link
                  className="text-blue-600 hover:underline"
                  to="/dashboard/documentation"
                >
                  Zie: Hoe integreer ik de chatbot in mijn website?
                </Link>
              </label>
            </div>
            <div className="w-full">
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Chatbot Naam
              </label>
              <input
                type="text"
                name="name"
                id="name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Chatbot name"
                required=""
                {...register("chatbotName", { required: true })}
              ></input>
            </div>
            <div className="w-full">
              <label
                htmlFor="brand"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Chatbot Logo
              </label>

              <FileUploader
                id="chatbot-logo"
                required={false}
                defaultUrl={defaultLogoUrl}
                fileUrl={chatbotLogoUrl}
                setUrlCallback={(logoUrl) => {
                  console.log(`setting chatbotLogoUrl: ${logoUrl}`);
                  setValue("chatbotLogoUrl", logoUrl);
                }}
              ></FileUploader>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="description"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Beschrijving
              </label>
              <textarea
                id="description"
                rows="8"
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Write a product description here..."
                {...register("description", { required: true })}
              ></textarea>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="brand"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Welkomst Bericht
              </label>
              <textarea
                id="description"
                rows="8"
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Write a product description here..."
                {...register("welcomeMessage", { required: true })}
              ></textarea>
            </div>
          </div>
          <div className="flex items-center space-x-4">
            <button
              type="submit"
              className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              Wijzigingen opslaan
            </button>
            <button
              type="button"
              className="text-red-600 inline-flex items-center hover:text-white border border-red-600 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900"
            >
              <svg
                className="w-5 h-5 mr-1 -ml-1"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.53033 3.46967C7.82322 3.76256 7.82322 4.23744 7.53033 4.53033L5.81066 6.25H15C18.1756 6.25 20.75 8.82436 20.75 12C20.75 15.1756 18.1756 17.75 15 17.75H8.00001C7.58579 17.75 7.25001 17.4142 7.25001 17C7.25001 16.5858 7.58579 16.25 8.00001 16.25H15C17.3472 16.25 19.25 14.3472 19.25 12C19.25 9.65279 17.3472 7.75 15 7.75H5.81066L7.53033 9.46967C7.82322 9.76256 7.82322 10.2374 7.53033 10.5303C7.23744 10.8232 6.76256 10.8232 6.46967 10.5303L3.46967 7.53033C3.17678 7.23744 3.17678 6.76256 3.46967 6.46967L6.46967 3.46967C6.76256 3.17678 7.23744 3.17678 7.53033 3.46967Z"
                ></path>
              </svg>
              Ongedaan maken
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

// export default Settings;
export default withRequiresAuth(Settings);
