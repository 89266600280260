import { COMPANY_EMAIL, COMPANY_NAME } from "./constants";

function TermsOfService() {
  return (
    <main className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 antialiased">
      <div className="flex justify-between px-4 mx-auto max-w-screen-xl">
        <article className="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
          <h1 className="text-3xl font-bold text-gray-900 mb-4">
            Gebruiksvoorwaarden
          </h1>

          <h2 className="text-2xl font-semibold text-gray-800 mt-5 mb-3">
            1. Acceptatie van de Voorwaarden
          </h2>
          <p className="text-gray-700 mb-3">
            Door het gebruiken van de diensten van {COMPANY_NAME}, gaat u
            akkoord met deze Gebruiksvoorwaarden. Indien u niet akkoord gaat,
            dient u onze diensten niet te gebruiken.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mt-5 mb-3">
            2. Wijzigingen in Voorwaarden
          </h2>
          <p className="text-gray-700 mb-3">
            {COMPANY_NAME} behoudt zich het recht voor om deze voorwaarden op
            elk moment te wijzigen of aan te passen. Wij adviseren gebruikers om
            deze pagina regelmatig te raadplegen.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mt-5 mb-3">
            3. Gebruik van de Dienst
          </h2>
          <p className="text-gray-700 mb-3">
            De diensten van {COMPANY_NAME} mogen niet gebruikt worden voor
            onwettige of ongeautoriseerde doeleinden. Gebruikers zijn zelf
            verantwoordelijk voor hun gedrag en de inhoud die zij delen.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mt-5 mb-3">
            4. Beperking van Aansprakelijkheid
          </h2>
          <p className="text-gray-700 mb-3">
            {COMPANY_NAME} is niet aansprakelijk voor directe of indirecte
            schade die voortvloeit uit het gebruik van onze diensten.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mt-5 mb-3">
            5. Intellectueel Eigendom
          </h2>
          <p className="text-gray-700 mb-3">
            Alle rechten van intellectueel eigendom met betrekking tot de dienst
            en de inhoud blijven eigendom van {COMPANY_NAME} of haar
            licentiegevers.
          </p>

          <h2 className="text-2xl font-semibold text-gray-800 mt-5 mb-3">
            6. Contact
          </h2>
          <p className="text-gray-700 mb-3">
            Bij vragen of opmerkingen over deze Gebruiksvoorwaarden kunt u
            contact opnemen via {COMPANY_EMAIL}.
          </p>
        </article>
      </div>
    </main>
  );
}

export default TermsOfService;
