import React from "react";
import useTrialStore from "./hooks/trial-store";

function FormField({ name, value }) {
  const updateTrialRegistrationData = useTrialStore(
    (state) => state.updateTrialRegistrationData,
  );
  return (
    <>
      <div className="mb-4 flex flex-col">
        <label>{name}</label>
        <input
          type="text"
          className="bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          onChange={(e) =>
            updateTrialRegistrationData({ [name]: e.target.value })
          }
          value={value}
        ></input>
      </div>
    </>
  );
}

export default function StatePoc() {
  const trialRegistrationData = useTrialStore(
    (state) => state.trialRegistrationData,
  );
  return (
    <div className="p-4 bg-gray-100">
      <pre className="border p-4 rounded bg-white text-xs text-gray-500 dark:text-gray-400">
        {JSON.stringify(trialRegistrationData, null, 4)}
      </pre>
      <div className="flex flex-col">
        <FormField
          name={"fullname"}
          value={trialRegistrationData.fullname}
        ></FormField>
        <FormField
          name={"country"}
          value={trialRegistrationData.country}
        ></FormField>
        <FormField
          name={"chatbotName"}
          value={trialRegistrationData.chatbotName}
        ></FormField>
        <FormField
          name={"chatbotLogoUrl"}
          value={trialRegistrationData.chatbotLogoUrl}
        ></FormField>
      </div>
    </div>
  );
}
