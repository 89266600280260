import { Navbar } from "flowbite-react";
import { NavLink } from "react-router-dom";

// this component is nasty because navlink is an anchor, and navbar.link is li.a so it wraps anchor with anchor
// but there is no other way without completely re-implementing what Navlink does combined with Navbar.Link
function MyNavlink({ to, children }) {
    return (
        <NavLink to={to}>
            {({ isActive, isPending, isTransitioning }) => (
                <Navbar.Link active={isActive}>{children}</Navbar.Link>
            )}
        </NavLink>
    );
}

export default MyNavlink;
