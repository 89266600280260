import React from "react";
import ClipboardIcon from "../icons/ClipboardIcon";

const ReadonlyCopyInput = ({ id, value }) => {
  const copyToClipboard = (e) => {
    e.preventDefault();
    const input = document.getElementById(id);
    input.disabled = false;
    input.select();
    document.execCommand("copy");
    input.disabled = true;
  };

  return (
    <div className="relative">
      <input
        id={id}
        type="text"
        value={value}
        required={false}
        readOnly
        disabled
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
      />
      <button
        onClick={copyToClipboard}
        className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-500"
      >
        <ClipboardIcon />
      </button>
    </div>
  );
};

export default ReadonlyCopyInput;
